import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import '@decathlon/vitamin/dist/index.css';
import '@decathlon/vitamin/dist/icons/icons.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import weloopai from '@weloopai/sdk';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../store/store';
import { selectIsMobile, selectTitle } from '../store/selectors/app';
import { InterventionsProvider } from './PlanningPage/InterventionsProvider';
import { ParcelsFlowProvider } from './ParcelsFlowPage/ParcelsFlowProvider';
import { CountryProvider } from '../utils/countryProvider/CountryProvider';
import { AutofocusProvider } from './SearchBar/AutofocusProvider';
import { detectIsMobileWidth, setIsMobile } from '../store/appSlice';
import { selectLanguage } from '../store/i18n.selectors';
import { initAuthService } from '../store/authSlice';
import { useConfigStore, useGtmUserArgs } from '../store/hooks';
import { setHasMedallia, setIsFreeClosingAuthorized } from '../store/configSlice';
import { initializeMedallia, setGlobalValuesForMedallia } from '../medallia';
import { RoutesPath, getAppRoute } from '../core/routes';
import { LazyReact, lazyWithSuspense } from './LazyReact/LazyReact';
import { LoaderProvider } from '../utils/loader/LoaderProvider';
import { ToastProvider } from '../utils/toaster/ToastProvider';
import { type ServiceApiName } from '../utils/config/configuration.type';
import ToastWrapper from '../utils/toaster/ToastWrapper';
import AppErrorBoundary from './AppErrorBoundary';
import StartUp from './StartUp/StartUp';
import { PageLayout } from './PageLayout/PageLayout';
import { Authentication } from './auth/Authentication';
import { ProductProvider } from './ProductPage/ProductProvider';
import { selectIsBookingsEnabled, selectUserDisplayName } from '../store/selectors/userInfo';
import { selectConfiguration } from '../store/selectors/configuration';

const SearchPage = lazyWithSuspense(() => import('./SearchPage/SearchPage'));
const HomePage = lazyWithSuspense(() => import('./HomePage/HomePage'));
const SparePartOrdersPage = lazyWithSuspense(async () => ({
  default: (await import('../pages/SparePartOrders')).SparePartOrders,
}));
const InterventionPage = lazyWithSuspense(() => import('./InterventionPage/InterventionPage'));
const PlanningPage = lazyWithSuspense(() => import('./PlanningPage/PlanningPage'));
const ParcelsFlowPage = lazyWithSuspense(() => import('./ParcelsFlowPage/ParcelsFlowPage'));
const ErrorPage = lazyWithSuspense(() => import('./ErrorPage/ErrorPage'));
const CustomerInterventions = lazyWithSuspense(() => import('./CustomerInterventions/CustomerInterventions'));
const MassiveInvoicePage = lazyWithSuspense(() => import('./MassiveInvoicePage/MassiveInvoicePage'));
const InterventionsContainer = lazyWithSuspense(() => import('./Interventions/InterventionsContainer'));
const DelaysParameters = lazyWithSuspense(() => import('./DelaysParameters/DelaysParameters'));
const LoadForecastPage = lazyWithSuspense(() => import('./LoadForecastPage/LoadForecastPage'));
const CatalogPage = lazyWithSuspense(() => import('./CatalogPage/CatalogPage'));
const ProductPage = lazyWithSuspense(() => import('./ProductPage/ProductPage'));
const CustomerPage = lazyWithSuspense(() => import('./Customer/CustomerPage'));
const CustomerAddOrUpdate = lazyWithSuspense(() => import('./CustomerAddOrUpdate/CustomerAddOrUpdate'));
const ShippingGroup = lazyWithSuspense(() => import('./ShippingGroup/ShippingGroup'));
const TableSearch = lazyWithSuspense(() => import('./TableSearch/TableSearch'));
const AdminPage = lazyWithSuspense(() => import('./AdminPage/AdminPage'));
const BookingsPage = lazyWithSuspense(async () => ({
  default: (await import('../pages/Bookings')).BookingsPage,
}));

interface ConfigSecretUrl {
  clientId: string;
  clientSecret: string;
  bfftnr?: string;
  idpUrl: string;
  isProd: boolean;
  hasFrontMonitoring: boolean;
  hasMedallia: boolean;
  GTMTrackingID: string;
  isFreeClosingAuthorized: boolean;
  hideEventNotifications: boolean;
  hasLog?: boolean;
  urls: Record<ServiceApiName, string>;
}

export const initializeGTM = (GTMTrackingID: string) => {
  const tagManagerArgs = {
    gtmId: GTMTrackingID,
    dataLayerName: 'dataLayer',
  };

  TagManager.initialize(tagManagerArgs);
};

const headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

export const App = () => {
  const dispatch = useAppDispatch();
  const title = useSelector(selectTitle);
  const lang = useSelector(selectLanguage);
  const displayName = useSelector(selectUserDisplayName);
  const isBookingsEnabled = useSelector(selectIsBookingsEnabled);
  const config = useSelector(selectConfiguration);
  const isMobile = useSelector(selectIsMobile);

  const { setIsProd, setHasLog, updateDomains, updateSecrets, setGTMTrackingID, setHideEventNotifications } = useConfigStore();
  const [userReady, setUserReady] = useState(false);
  const [appError, setAppError] = useState(false);
  const GTMTrackingID = config?.GTMTrackingID;
  const { userJobname, userUID, languageCode, selectedSite } = useGtmUserArgs();
  const countryCode = selectedSite.address.countryCode;

  useEffect(() => {
    fetch(new URL('/config.env.json', location.origin).toString(), { headers })
      .then(async response => {
        const configResponse: ConfigSecretUrl = await response.json();
        setIsProd(configResponse.isProd);
        dispatch(setHasMedallia(configResponse.hasMedallia));
        setGTMTrackingID(configResponse.GTMTrackingID);
        dispatch(setIsFreeClosingAuthorized(configResponse.isFreeClosingAuthorized));
        setHideEventNotifications(configResponse.hideEventNotifications);
        setHasLog(configResponse.isProd || Boolean(configResponse.hasLog));

        if (window.parent.Cypress && configResponse.bfftnr) {
          configResponse.urls.ICARE_BACK = configResponse.bfftnr;
        }

        updateDomains({
          urls: configResponse.urls,
          idpUrl: configResponse.idpUrl,
        });
        updateSecrets({ clientId: configResponse.clientId, clientSecret: configResponse.clientSecret });
        configResponse.hasFrontMonitoring && import('../utils/instrumentation/instrumentationDatadog').then(({ initMonitoring }) => initMonitoring());
      })
      .catch(() => {
        setAppError(true);
      });
  }, []);

  useEffect(() => {
    const {
      config: { idpUrl },
      secrets: { clientId, clientSecret },
    } = config;
    if (!userReady && idpUrl) {
      const isNewAuth = !clientId && !clientSecret;

      localStorage.setItem('authVersion', isNewAuth ? 'new' : 'old');

      dispatch(initAuthService({ idpUrl, clientId, clientSecret }));
      setUserReady(true);
    }
  }, [config]);

  useEffect(() => {
    if (displayName) {
      datadogRum.setUserProperty('name', displayName);
    }
  }, [displayName]);

  useEffect(() => {
    GTMTrackingID && initializeGTM(GTMTrackingID);
  }, [GTMTrackingID]);

  useEffect(() => {
    if (config.hasMedallia && userJobname && languageCode && process.env.ICARE_ENV && countryCode) {
      initializeMedallia();
      setGlobalValuesForMedallia({
        jobName: userJobname,
        uid: userUID,
        languageCode,
        userCountry: countryCode,
        storeId: selectedSite.id.toString(),
        env: process.env.ICARE_ENV,
      });
    }
  }, [config.hasMedallia, userJobname && languageCode && process.env.ICARE_ENV]);

  const handleWindowSizeChange = useCallback(() => {
    dispatch(setIsMobile(detectIsMobileWidth()));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  useEffect(() => {
    document.title = title ? `Icare - ${title}` : 'Icare';
  }, [title]);

  useEffect(() => {
    lang && document.documentElement.setAttribute('lang', lang);
  }, [lang]);

  useEffect(() => {
    if (!window.parent.Cypress) {
      if (appError || userReady) {
        const WELOOP_PROJECT_ID = '55f09060-e1b2-11ec-9857-1513f4c24aca';

        weloopai.init({ key: WELOOP_PROJECT_ID });

        return () => {
          weloopai.destroy();
        };
      }
    }

    return undefined;
  }, [appError, userReady]);

  if (!userReady) {
    return null;
  }

  if (appError) {
    return <p>An unexpected error occurred. Please try again later.</p>;
  }

  if (location.pathname === getAppRoute(RoutesPath.adminPage)) {
    return (
      <Routes>
        <Route path={getAppRoute(RoutesPath.adminPage)} element={<AdminPage />} />
      </Routes>
    );
  }

  return (
    <LazyReact>
      <AppErrorBoundary>
        <Authentication>
          <ToastProvider>
            <ToastWrapper />
            <StartUp>
              <LoaderProvider>
                <CountryProvider>
                  <ProductProvider>
                    <AutofocusProvider>
                      <PageLayout>
                        <ParcelsFlowProvider>
                          <InterventionsProvider>
                            <Routes>
                              <Route path={getAppRoute(RoutesPath.homePage)} element={<HomePage />} />
                              <Route path={getAppRoute(RoutesPath.sparePartOrders)} element={<SparePartOrdersPage />} />
                              <Route path={getAppRoute(RoutesPath.interventionPage, '*')}>
                                <Route path=":interventionId" element={<InterventionPage />} />
                                <Route path="" element={<InterventionPage />} />
                              </Route>
                              <Route path={getAppRoute(RoutesPath.planningCustomerInterventionPage)} element={<CustomerInterventions />} />
                              <Route path={getAppRoute(RoutesPath.parcelFlowPage)} element={<ParcelsFlowPage />} />
                              <Route path={getAppRoute(RoutesPath.massiveInvoicePage)} element={<MassiveInvoicePage />} />
                              <Route path={getAppRoute(RoutesPath.catalogRootPage, ':type')} element={<CatalogPage />} />
                              <Route path={getAppRoute(RoutesPath.customerPage, ':memberId')} element={<CustomerPage />} />
                              <Route path={getAppRoute(RoutesPath.customerCreatePage)} element={<CustomerAddOrUpdate />} />
                              <Route path={getAppRoute(RoutesPath.customerUpdatePage, ':memberId')} element={<CustomerAddOrUpdate />} />
                              <Route path={getAppRoute(RoutesPath.errorPage)} element={<ErrorPage />} />
                              <Route path={getAppRoute(RoutesPath.productPage, '*')}>
                                <Route path=":type" element={<ProductPage />} />
                                <Route path="" element={<ProductPage />} />
                              </Route>
                              <Route path={getAppRoute(RoutesPath.shippingGroupPage, ':externalRef')} element={<ShippingGroup />} />
                              {!isMobile && (
                                <>
                                  <Route path={getAppRoute(RoutesPath.planningPage)} element={<PlanningPage />} />
                                  <Route path={getAppRoute(RoutesPath.searchPage)} element={<SearchPage />} />
                                  <Route path={getAppRoute(RoutesPath.searchResultPage)} element={<TableSearch />} />
                                  <Route path={getAppRoute(RoutesPath.planningInterventionPage)} element={<InterventionsContainer />} />
                                  <Route path={getAppRoute(RoutesPath.forecastPage)} element={<LoadForecastPage />} />
                                  <Route path={getAppRoute(RoutesPath.delayParamPage)} element={<DelaysParameters />} />
                                  {isBookingsEnabled && <Route path={getAppRoute(RoutesPath.bookingsPage)} element={<BookingsPage />} />}
                                </>
                              )}
                              <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                          </InterventionsProvider>
                        </ParcelsFlowProvider>
                      </PageLayout>
                    </AutofocusProvider>
                  </ProductProvider>
                </CountryProvider>
              </LoaderProvider>
            </StartUp>
          </ToastProvider>
        </Authentication>
      </AppErrorBoundary>
    </LazyReact>
  );
};
